<template>
	<div class="">
		<qy-top-nav :title="userId?'门店编辑':'新增门店'" />
		<van-form @submit="submit" label-width="60" style="margin-top: 12px;">
			<van-cell-group>
				<van-field v-model="phone" label="手机号" placeholder=""
					:rules="[{ required: true, message: '请输入手机号' }]" :disabled="userId?true:false" />

			</van-cell-group>
			<van-cell-group>
				<van-field v-model="userName" label="姓名" placeholder=""
					:rules="[{ required: true, message: '请输入姓名' }]" />
			</van-cell-group>
			<van-cell-group>
				<van-field v-model="cityInfo" label="城市" placeholder="" @focus="showCityPanel" />
			</van-cell-group>
			<van-cell-group>
				<van-field v-model="address" label="详细地址" placeholder=""
					:rules="[{ required: true, message: '请输入详细地址' }]" />
			</van-cell-group>
			<!-- <div class="flex-between" style="background-color: #fff;padding:10px 15px;font-size:14px;;margin:12px 0;;"> 
				<span>类别：</span>
				<van-radio-group v-model="tag" direction="horizontal">
					<van-radio :name="tag.dictValue" v-for="tag in tagList">{{tag.dictLabel}}</van-radio>
				</van-radio-group>
			</div> -->
			
			<div class="qy-panel"> 
				<van-button type="warning" color="#ffad1a" block native-type="submit">保存</van-button>
			</div>
			<van-popup v-model="showCity" position="bottom" :overlay="false">
				<van-area title="选择城市" :area-list="areaList" @cancel="showCity=false" @confirm="onConfirmCity" />
			</van-popup>
		</van-form>



	</div>
</template>
<script type="text/javascript">
	import { areaList } from '@vant/area-data';
	export default {
		name: "customerEdit",
		data() {
			return {
				userId: "",
				phone: "",
				userName: "",
				cityInfo: "",
				areaCode: "",
				province:"",
				city:"",
				county:"",
				address: "",
				tagList:[],
				areaList:areaList,
				tag: "C",
				showCity:false
			}
		},

		created() {
			this.userId = this.getUrlParam("userId") || "";
			this.fetchDetail();
		},
		methods: {
			fetchDetail() {
				this.doPost("/api/common/dict", {
					type: "customer_type"
				}, (rs) => {
					this.tagList = rs;
				});
				if(this.userId){
					this.doPost('/api/proxy/oper/getClient',{userId:this.userId},(rs)=>{
						if(rs.success){
							this.phone=rs.data.phone;
							this.userName=rs.data.userName;
							this.tag=rs.data.tag;
							let otherInfo=JSON.parse(rs.data.otherInfo||'{}');
							this.province=otherInfo.province;
							this.city=otherInfo.city;
							this.county=otherInfo.county;
							this.cityInfo=otherInfo.cityInfo;
							this.areaCode=otherInfo.areaCode;
							this.address=otherInfo.address;
						}
					})
				}
			},
			submit() {
				let url=this.userId?'/api/proxy/oper/editClient':'/api/proxy/oper/addClient';
				if (!this.phone) {
					this.Toast("请输入手机号!")
					return;
				}
				if(!this.tool.isMobile(this.phone)){
					this.Toast("手机号不正确!")
					return;
				}
				this.doPost(url, {
					userId: this.userId,
					userName: this.userName,
					phone: this.phone,
					tag:this.tag,
					province:this.province,
					city:this.city,
					county:this.county,
					cityInfo:this.cityInfo,
					areaCode:this.areaCode,
					address:this.address
				}, rs => {
					if (rs.success) {
						this.alert("保存成功", () => {
							this.navigateBack();
						})
					} else {
						this.alert(rs.msg);
					}
				});
			},
			onConfirmCity(dArr){
				if(!dArr){
					return;
				}
				let arr=[];
				for(let i=0;i<dArr.length;i++){
					if(i==1&&dArr[i].name==dArr[0].name){
						continue;
					}
					arr.push(dArr[i].name);
				}
				this.cityInfo=arr.join("/");
				this.province=dArr[0].name;
				this.city=dArr[0].name;
				this.county=dArr[0].name;
				this.areaCode=dArr[dArr.length-1].code;
				this.showCity=false;
			},
			showCityPanel(e){
				this.showCity=true;
				e.target.blur();
			}
		}
	}
</script>
<style type="text/css">

</style>